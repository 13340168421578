@font-face {
  font-family: 'Inter-Regular';
  src: url('./assets/fonts/Inter-Regular.ttf');
}

@font-face {
  font-family: 'Inter-SemiBold';
  src: url('./assets/fonts/Inter-SemiBold.ttf');
}

:root {
  --success: #00ff00;
  --error: #8594ac;
  --accent50: #EEEEEE;
  --accent500: #00ADB5;
  --primary400: #393E46;
  --primary500: #222831;

  --mobile-width-px: 768px;
}

body {
  margin: 0;
  font-family: 'Inter-Regular', sans-serif;
  font-weight: 400;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  
  background-color: var(--primary500);
  color: var(--accent50);
  padding-inline: 15%;
  padding-bottom: 15%;
}

h1, h2, h3, p {
  margin: 0;
}

@media screen and (max-width: 768px) {
  /* :root {
    font-size: 14px;
  } */

  body {
    padding-inline: 5%;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

*,
*:after,
*:before {
  box-sizing: border-box;
}
