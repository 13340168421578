.grid-card {
  display: flex;
  flex-direction: column;
  max-height: 350px;
}

.grid-card__header {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.grid-card__actions-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 12px;
}

.grid-card__title {
  font-size: 1.2rem;
  font-family: 'Inter-SemiBold', sans-serif;
  font-weight: 700;
  text-wrap: nowrap;
}

.grid-card__icon {
  opacity: 1;
  cursor: pointer;
  transition: opacity .25s linear;
}

.grid-card__icon:hover {
  opacity: .8;
}

.grid-card__icon:active {
  opacity: .5;
}
