.nav-bar {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  margin-bottom: 3rem;
}
  /* background-color: var(--primary400); */


.nav-bar__menu {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  column-gap: 2.5rem;
  width: 100%;
}

.nav-bar__link {
  padding-block: 1.5rem;
  cursor: pointer;
  list-style: none;
  transition: text-shadow .1s ease, opacity .1s ease;
  -webkit-transition: text-shadow .1s ease, opacity .1s ease;
}

.nav-bar__link:hover {
  text-shadow: 0 0 8px var(--accent500);
  opacity: .8;
}

.nav-bar__link {
  text-decoration: none;
  color: var(--accent50);
  
}

.nav-bar__link.active {
  font-weight: 700;
  font-family: 'Inter-SemiBold', sans-serif;
  text-shadow: 0 0 8px var(--accent500);
}