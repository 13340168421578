.grid-screen__title {
  margin-bottom: 2.5rem;
  text-align: center;
  font-weight: 700;
  font-family:  'Inter-SemiBold', sans-serif;
}

.grid-screen__fallback-text {
  text-align: center;
  color: var(--error);
}