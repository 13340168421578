.floating-icon-button {
  position: fixed;
  right: 1.5rem;
  bottom: 1.5rem;

  opacity: 1;
  padding: 16px;
  border-radius: 50%;
  transition: opacity .25s linear;

  background-color: var(--accent500);
  box-shadow: 0 0 4px var(--accent500);
  cursor: pointer;
}

.floating-icon-button:hover {
  opacity: .8;
}

.floating-icon-button:active {
  opacity: .5;
}