.card {
  padding: 1rem;
  border-radius: .5rem;
  box-shadow: 0px 0px 4px var(--primary400);
  background-color: var(--primary400);
}

.message {
  font-size: .85rem;
  font-weight: 700;
  font-family: 'Inter-SemiBold';
}

.message--error {
  color: var(--error);
}

.message--success {
  color: var(--success);
}

.input {
  outline: none;
  color: var(--accent50);
  background-color: var(--primary500);
  border: none;
  border-radius: .25rem;
  font-size: 1rem;
  padding-block: .25rem;
  padding-left: 4px;
  font-family: 'Inter-Regular';
  font-weight: 400;
}