.button {
  opacity: 1;
  cursor: pointer;
  transition: opacity .25s linear;
}

.button:hover {
  opacity: .8;
}

.button:active {
  opacity: .5;
}
