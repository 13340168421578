.input-text {
  display: flex;
  flex-direction: column;
  row-gap: 6px;
  width: 100%;
}

.input-text__label {
  font-weight: 700;
  font-family: 'Inter-SemiBold', sans-serif;
  font-size: .9rem;
  cursor: pointer;
}